import { Outlet, useParams } from "react-router-dom";
import LeftSidebar from "../components/potree/project/LeftSidebar";
import RightSidebar from "../components/potree/project/RightSidebar";
import TabsBar from "../components/potree/project/TabsBar";
import Overlay from "../components/potree/shared/Overlay";
import Footer from "../components/potree/shared/Footer";

const PotreeLayout: React.FC = () => {
  const { presentationId } = useParams();

  return (
    <>
      {/* Don't show left sidebar on presentation route (TODO: improve this logic later) */}
      {!presentationId && <LeftSidebar />}

      <main className="h-screen flex items-center justify-center bg-white project-layout">
        <Outlet />
      </main>

      <RightSidebar />

      <TabsBar />

      <Overlay />

      <Footer />
    </>
  );
};

export default PotreeLayout;
