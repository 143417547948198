import uniqid from "uniqid";
import { POTREE_DISPLAY_MODES } from "../../../constants/potree";
import { potreeMeasuringToolsHelper } from "../../../utils/potreeMeasuringToolsHelper";
import { useToggle } from "../../../hooks/useToggle";
import { usePotree } from "../../../hooks/usePotree";
import Wrapper from "../shared/Wrapper";

const TabsBar = () => {
  const { potreeViewer } = usePotree();
  const { potreeDisplayMode, setPotreeDisplayMode } = useToggle();

  if (!potreeViewer) return null;

  return (
    <Wrapper
      elementType="section"
      classNames="z-10 absolute bottom-6 left-1/2 transform -translate-x-1/2 rounded-lg p-2 bg-lgray"
    >
      <ul className="flex flex-row items-center gap-3">
        {POTREE_DISPLAY_MODES.map((displayMode) => (
          <li
            key={uniqid()}
            onClick={() => {
              setPotreeDisplayMode(displayMode.value);
              potreeMeasuringToolsHelper[displayMode.action](potreeViewer);
            }}
            className={`transition cursor-pointer px-4 py-2 rounded-lg ${
              potreeDisplayMode === displayMode.value
                ? "bg-primary text-white"
                : "text-gray"
            }`}
          >
            {
              POTREE_DISPLAY_MODES.find(
                ({ value }) => value === displayMode.value
              )?.label
            }
          </li>
        ))}
      </ul>
    </Wrapper>
  );
};

export default TabsBar;
