const Heading = ({
  title,
  classNames,
}: {
  title: string;
  classNames?: string;
}) => {
  return <p className={`text-lg-regular ${classNames ?? ""}`}>{title}</p>;
};

export default Heading;
