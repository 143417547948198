export const MENU = {
  View: [
    {
      toolName: "split screen",
      action: null,
      toolIconSrc: "split-screen.svg",
    },
    {
      toolName: "camera",
      action: null,
      toolIconSrc: "camera.svg",
    },
  ],
  Navigate: [
    {
      toolName: "select",
      action: null,
      toolIconSrc: "select.svg",
    },
    {
      toolName: "box",
      action: null,
      toolIconSrc: "box.svg",
    },
  ],
  Measure: [
    {
      toolName: "height",
      action: "addHeightMeasurement",
      toolIconSrc: "vertical.svg",
    },
    {
      toolName: "horizontal",
      action: "addHorizontalMeasurement",
      toolIconSrc: "horizontal.svg",
    },
    {
      toolName: "distance",
      action: "addDistanceMeasurement",
      toolIconSrc: "incline.svg",
    },
    {
      toolName: "point",
      action: "addPointMeasurement",
      toolIconSrc: "dot.svg",
    },
    {
      toolName: "filter",
      action: "addRemoveMeasurements",
      toolIconSrc: "filter.svg",
    },
  ],
  Component: [
    {
      toolName: "plus",
      action: null,
      toolIconSrc: "plus.svg",
    },
  ],
  Keynote: [
    {
      toolName: "keynote",
      action: null,
      toolIconSrc: "keynote.svg",
    },
  ],
};
