import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { Project } from "../../types";
import { DEFAULTS } from "../../constants/defaults";
import ProjectEditMenu from "./ProjectEditMenu";

const ProjectsList = ({ projects }: { projects: Project[] }) => {
  const tableHeadContent = (
    <tr>
      <th scope="col" className="text-white pr-4 py-2">
        #
      </th>
      <th scope="col" className="px-4 py-2">
        Project
      </th>
      <th scope="col" className="px-4 py-2">
        Location
      </th>
      <th scope="col" className="px-4 py-2">
        Created
      </th>
      <th scope="col" className="px-4 py-2">
        Updated
      </th>
      <th scope="col"></th>
    </tr>
  );

  let tableBodyContent;
  if (!projects || projects.length <= 0) {
    tableBodyContent = (
      <tr className="border-b border-gray">
        <td className="py-2">No Project Found!</td>
      </tr>
    );
  } else {
    tableBodyContent = projects.map((project: Project, index: number) => (
      <tr
        key={`project_${project.id}_${index}`}
        className="border-b border-gray"
      >
        <th scope="row" className="pr-4 py-2 w-2">
          {index + 1}
        </th>

        <td className="px-4 py-2 hover:underline">
          <Link
            to={`/project/${project.id}`}
            className="visited:text-white flex"
          >
            {project.label}
          </Link>
        </td>

        <td className="px-4 py-2">Berlin</td>
        <td className="px-4 py-2">
          {dayjs(Number(project.created) * 1000).format(
            DEFAULTS.formatDateTime
          )}
        </td>
        <td className="px-4 py-2">
          {dayjs(Number(project.changed) * 1000).format(
            DEFAULTS.formatDateTime
          )}
        </td>
        <td className="pl-4 py-2 w-2">
          <ProjectEditMenu projectId={project.id} />
        </td>
      </tr>
    ));
  }

  return (
    <table className="w-full text-left">
      <thead className="text-secondary">{tableHeadContent}</thead>
      <tbody>{tableBodyContent}</tbody>
    </table>
  );
};

export default ProjectsList;
