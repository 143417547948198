import { OrtoScene, PointCloudOctree, SceneDataPointcloud } from "../types";

type PotreeMeasuringToolsHelper = {
  [key: string]: (viewer?: any, ortoScene?: OrtoScene) => void;
};

export const potreeMeasuringToolsHelper: PotreeMeasuringToolsHelper = {
  addAngleMeasurement: (viewer) => {
    viewer.measuringTool.startInsertion({
      showDistances: false,
      showAngles: true,
      showArea: false,
      closed: true,
      maxMarkers: 3,
      name: "Angle",
    });
  },

  addPointMeasurement: (viewer) => {
    viewer.measuringTool.startInsertion({
      showDistances: false,
      showAngles: false,
      showCoordinates: true,
      showArea: false,
      showElevation: true,
      closed: true,
      maxMarkers: 1,
      name: "Point",
    });
  },

  addDistanceMeasurement: (viewer) => {
    viewer.measuringTool.startInsertion({
      showDistances: true,
      showArea: false,
      closed: false,
      name: "Distance",
    });
  },

  addHeightMeasurement: (viewer) => {
    viewer.measuringTool.startInsertion({
      showDistances: false,
      showHeight: true,
      showArea: false,
      closed: false,
      maxMarkers: 2,
      name: "Height",
    });
  },

  addCircleMeasurement: (viewer) => {
    viewer.measuringTool.startInsertion({
      showDistances: false,
      showHeight: false,
      showArea: false,
      showCircle: true,
      showEdges: false,
      closed: false,
      maxMarkers: 3,
      name: "Circle",
    });
  },

  addAzimuthMeasurement: (viewer) => {
    viewer.measuringTool.startInsertion({
      showDistances: false,
      showHeight: false,
      showArea: false,
      showCircle: false,
      showEdges: false,
      showAzimuth: true,
      closed: false,
      maxMarkers: 2,
      name: "Azimuth",
    });
  },

  addAreaMeasurement: (viewer) => {
    viewer.measuringTool.startInsertion({
      showDistances: true,
      showArea: true,
      closed: true,
      name: "Area",
    });
  },

  addHorizontalMeasurement: (viewer) => {
    viewer.measuringTool.startInsertion({
      showDistances: false,
      showHorizontalDist: true,
      showArea: false,
      closed: false,
      maxMarkers: 2,
      name: "Horizontal distance",
    });
  },

  addRemoveMeasurements: (viewer) => {
    let scene = viewer.scene;
    while (scene.measurements.length > 0) {
      scene.removeMeasurement(scene.measurements[0]);
    }
  },

  showModel: (viewer) => {
    viewer.showBoundingBox = false;
    viewer.useEDL = true;
    viewer.setBackground("white");
    viewer.useHQ = true;
    viewer.setFrontView();
    viewer.setCameraMode(1);
    viewer.scene.volumes[0].visible = true;
    viewer.setControls(viewer.orbitControls);

    for (let i = 0; i < viewer.scene.pointclouds.length; i++) {
      const material = viewer.scene.pointclouds[i].material;
      material._shape = 1;
      material.minSize = 0.1;
      material.opacity = 1;
      material.rgbGamma = 1;
      material.rgbBrightness = 0;
      material.rgbContrast = 0;
    }
  },

  setOrthogonalView: (viewer) => {
    viewer.showBoundingBox = false;
    viewer.useEDL = false;
    viewer.setBackground("black");
    viewer.useHQ = false;
    viewer.setTopView();
    viewer.setCameraMode(0);
    viewer.scene.volumes[0].visible = false;
    viewer.setControls(viewer.planeControls);

    for (let i = 0; i < viewer.scene.pointclouds.length; i++) {
      const material = viewer.scene.pointclouds[i].material;
      material._shape = 1;
      material.minSize = 1;
      material.opacity = 0.01;
      material.rgbGamma = 1.44;
      material.rgbBrightness = 0.6;
      material.rgbContrast = 1;
    }
  },

  showGrundriss: (viewer) => {
    potreeMeasuringToolsHelper.setOrthogonalView(viewer);
  },

  showSchnitt: (viewer) => {
    potreeMeasuringToolsHelper.setOrthogonalView(viewer);
  },

  getVolumesData: (viewer) => {
    const volumesData: any = [];

    // We actually need only one volume. Should have index=0 atm but better to check
    // UUID to be minimum ID 00000000-0000-0000-0000-000000000000.
    viewer.scene.volumes.forEach((volume: any, delta: number) => {
      volumesData[delta] = {
        position: volume.position,
        rotation: volume.rotation,
        scale: volume.scale,
        clip: volume.clip,
        visible: volume.visible,
      };
    });

    return volumesData;
  },

  setVolumesData: (viewer, ortoScene) => {
    // TODO iterate over json_data.
    const jsonData: any = ortoScene?.json_data[0].value;
    // TODO: iterate over volumes and match UUID, move code to a method setVolumeData().
    const volumeData: any = jsonData.volumes[0];
    // console.log("Show scene with volumes data: ", volumeData);
    // We need to resotre the volume attributes of the volume with
    // UUID 00000000-0000-0000-0000-000000000000. Currently there is
    // only one volume in the scene but there could be more in future.
    const clipVolume = viewer.scene.volumes[0];
    clipVolume.position.set(
      volumeData.position.x,
      volumeData.position.y,
      volumeData.position.z
    );
    clipVolume.scale.set(
      volumeData.scale.x,
      volumeData.scale.y,
      volumeData.scale.z
    );
    clipVolume.rotation.set(
      volumeData.rotation._x,
      volumeData.rotation._y,
      volumeData.rotation._z
    );
    clipVolume.clip = volumeData.clip;
    clipVolume.visible = volumeData.visible;
  },

  getViewData: (viewer) => {
    const camera = viewer.scene.getActiveCamera();
    const view = viewer.scene.view;
    const position = camera.position.toArray();
    const target = view.getPivot().toArray();
    const transitionDuration = 0;

    return { position, target, transitionDuration };
  },

  // Gets pointclouds data for Orto scene JSON.
  getPointcloudsData: (viewer: any): SceneDataPointcloud[] => {
    return viewer.scene.pointclouds.map(
      ({ visible, o_id }: PointCloudOctree) => ({
        visible,
        o_id,
      })
    );
  },

  /**
   * Field json_data in Orto scenes can contain multiple values.
   * ...
   *
   * @param {object} jsonData
   *   Data from field json_data of an Orto scene entity.
   */
  applyJsonData(jsonData: any) {
    // Iterate over jsonData.
    // For each item:
    // - setViewData
    // - setPointcloudsData
    // - setVolumesData
  },

};
