import { GoPlus } from "react-icons/go";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import Heading from "../shared/Heading";
import Button from "../shared/Button";

interface ProjectsInfoHeaderProps {
  activeProjects?: number;
  archiveProjects?: number;
}

const ProjectsInfoHeader: React.FC<ProjectsInfoHeaderProps> = ({
  activeProjects,
  archiveProjects,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  return (
    <section>
      <div className="flex flex-row justify-between items-center gap-4 h-16 mr-[134px]">
        <div className="flex flex-row items-center gap-4">
          <Heading title="Projects" />

          {location.pathname === "/dashboard" && (
            <Button
              icon={GoPlus}
              handleClick={() => navigate("/project/add")}
            />
          )}

          {location.pathname === "/project/add" && (
            <Button label="cancel" handleClick={() => navigate("/dashboard")} />
          )}
        </div>

        <nav>
          <ul className="hidden sm:flex flex-row gap-6 items-center">
            {activeProjects && activeProjects > 0 ? (
              <li>Active ({activeProjects})</li>
            ) : null}
            {archiveProjects && archiveProjects > 0 ? (
              <li>Archive ({archiveProjects})</li>
            ) : null}
          </ul>
        </nav>
      </div>
    </section>
  );
};

export default ProjectsInfoHeader;
