import uniqid from "uniqid";
import { toast } from "react-toastify";
import { VscArrowRight } from "react-icons/vsc";
import { GoPlus } from "react-icons/go";
import { HiOutlinePlus, HiOutlineMinus } from "react-icons/hi2";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { DEFAULTS } from "../../../constants/defaults";
import { PotreeDisplayModes, OrtoScene } from "../../../types";
import { potreeMeasuringToolsHelper } from "../../../utils/potreeMeasuringToolsHelper";
import { authTokenHeader } from "../../../utils/helper";
import { useAuth } from "../../../hooks/useAuth";
import { useToggle } from "../../../hooks/useToggle";
import { usePotree } from "../../../hooks/usePotree";
import Wrapper from "../shared/Wrapper";
import Accordion from "../shared/Accordion";
import Input from "../shared/Input";
import Button from "../../shared/Button";
import PopupMenu from "../shared/PopupMenu";
import Label from "../../shared/Label";
import Authenticated from "../shared/Authenticated";
import { OrtoSceneHelper } from "../../../utils/OrtoSceneHelper";

const DEFAULT_NEW_SCENE = {
  label: "",
  showForm: false,
};

const DEFAULT_EDIT_SCENE: {
  label: string;
  showFormId: string | null;
} = {
  label: "",
  showFormId: null,
};

interface SceneTypeAccordionProps {
  sceneTypeLabel: string;
  sceneTypeKey: string;
  sceneTypeScenes: OrtoScene[];
}

const SceneTypeAccordion: React.FC<SceneTypeAccordionProps> = ({
  sceneTypeLabel,
  sceneTypeKey,
  sceneTypeScenes,
}) => {
  const { token } = useAuth();
  const { potreeDisplayMode } = useToggle();
  const { project, setProject, potreeViewer, projectPcs, setProjectPcs } =
    usePotree();

  const theme =
    potreeDisplayMode === DEFAULTS.potreeDisplayTab ? "light" : "dark";

  const [newScene, setNewScene] = useState(DEFAULT_NEW_SCENE);
  const [editScene, setEditScene] = useState(DEFAULT_EDIT_SCENE);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  // Use a ref to reference the input field
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setErrors({});

    // When add new scene or update scene form is visible, focus on the label input field
    if ((newScene.showForm || editScene.showFormId) && inputRef.current) {
      inputRef.current.focus();
    }
  }, [newScene.showForm, editScene.showFormId]);

  useEffect(() => {
    console.log("display mode has changed", potreeDisplayMode);
  }, [potreeDisplayMode]);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    isEditSceneInput = false
  ) => {
    setErrors({});
    isEditSceneInput
      ? setEditScene((prev) => ({
        ...prev,
        label: e.target.value,
      }))
      : setNewScene((prev) => ({
        ...prev,
        label: e.target.value,
      }));
  };

  const handleViewScene = (scene: OrtoScene) => {
    setNewScene(DEFAULT_NEW_SCENE);
    setEditScene(DEFAULT_EDIT_SCENE);

    OrtoSceneHelper.applyOrtoScene(potreeViewer, scene);

    // Set volumes data in the potree scene.
    potreeMeasuringToolsHelper.setVolumesData(potreeViewer, scene);
    // Set pointclouds visibility in the potree scene.
    OrtoSceneHelper.setPointcloudsData(potreeViewer, scene, setProjectPcs);

    // TODO: Improve this logic to update pointclouds in checkbox
    // Show visible pointclouds only for this scene
    // const pointcloudsData = scene.json_data[0].value.pointclouds || [];
    // Set pointclouds visibility state in checkboxes
    // if (pointcloudsData.length === projectPcs.length) {
    //   setProjectPcs((prev: any) => {
    //       console.log('hello');
    //       return prev.map((item: any, index: number) => {
    //         item.visibile = pointcloudsData[index].visible;
    //         return item;
    //       });
    //     }
    //   )
    // } else {
    //   setProjectPcs((prev: any) =>
    //     prev.map((item: any) => {
    //       item.visibile = true;
    //       return item;
    //     })
    //   );
    // }
  };

  const addScene = async () => {
    const volumesData: any = potreeMeasuringToolsHelper.getVolumesData(potreeViewer);
    const viewData: any = OrtoSceneHelper.getViewData(potreeViewer);
    const pointcloudsData = potreeMeasuringToolsHelper.getPointcloudsData(potreeViewer);

    const body: any = JSON.stringify({
      label: newScene.label,
      field_scene_type: sceneTypeKey,
      json_data: [
        {
          type: "attributes",
          value: {
            volumes: volumesData,
            view: viewData,
            pointclouds: pointcloudsData,
          },
        },
      ],
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/project/${project?.id}/scene`,
        {
          method: "PUT",
          headers: authTokenHeader(token),
          body,
        }
      );

      const newScene = await response.json();

      if (newScene && response.ok) {
        toast.success(`Scene '${newScene.label}' added.`);

        setProject((prevProject: any) => ({
          ...prevProject,
          field_scenes: {
            ...prevProject.field_scenes,
            [sceneTypeKey]: {
              ...prevProject.field_scenes[sceneTypeKey],
              scenes: [
                ...prevProject.field_scenes[sceneTypeKey].scenes,
                newScene,
              ],
            },
          },
        }));

        return;
      }

      toast.error(`Adding scene '${newScene.label}' failed.`);
    } catch (error: any) {
      console.log("API request failed:", error.message);
      throw Error(error.message);
    }
  };

  const updateScene = async (sceneId: string, updatedSceneLabel: string) => {
    const volumesData: any = potreeMeasuringToolsHelper.getVolumesData(potreeViewer);
    const viewData: any = potreeMeasuringToolsHelper.getViewData(potreeViewer);
    const pointcloudsData: any = potreeMeasuringToolsHelper.getPointcloudsData(potreeViewer);

    const body: any = JSON.stringify({
      label: updatedSceneLabel,
      field_scene_type: sceneTypeKey,
      json_data: [
        {
          type: "attributes",
          value: {
            volumes: volumesData,
            view: viewData,
            pointclouds: pointcloudsData,
          },
        },
      ],
    });

    try {
      const response = await fetch(
        // `${process.env.REACT_APP_API_BASE_URL}/api/v1/scene/${sceneId}`,
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/scene/${sceneId}?XDEBUG_SESSION_START=PHPSTORM`,
        {
          method: "POST",
          headers: authTokenHeader(token),
          body,
        }
      );

      const updatedScene = await response.json();

      if (updatedScene && response.ok) {
        toast.success(`Scene '${updatedSceneLabel}' updated.`);

        setProject((prevProject: any) => ({
          ...prevProject,
          field_scenes: {
            ...prevProject.field_scenes,
            [sceneTypeKey]: {
              ...prevProject.field_scenes[sceneTypeKey],
              scenes: prevProject.field_scenes[sceneTypeKey].scenes.map(
                (scene: OrtoScene) =>
                  scene.id === sceneId ? updatedScene : scene
              ),
            },
          },
        }));

        return;
      }

      toast.error(`Updating scene '${updatedScene}' failed.`);
    } catch (error: any) {
      console.log("API request failed:", error.message);
      throw Error(error.message);
    }
  };

  const deleteScene = async (sceneId: string) => {
    const deletedSceneLabel = project?.field_scenes
      ? project?.field_scenes[sceneTypeKey as PotreeDisplayModes]?.scenes.find(
        (scene) => scene.id === sceneId
      )?.label
      : null;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/scene/${sceneId}`,
        {
          method: "DELETE",
          headers: authTokenHeader(token),
        }
      );

      const deletedScene = await response.json();

      if (deletedScene && response.ok) {
        toast.success(`Scene '${deletedSceneLabel}' deleted.`);

        setProject((prevProject: any) => ({
          ...prevProject,
          field_scenes: {
            ...prevProject.field_scenes,
            [sceneTypeKey]: {
              ...prevProject.field_scenes[sceneTypeKey],
              scenes:
                prevProject.field_scenes[sceneTypeKey]?.scenes.filter(
                  (scene: OrtoScene) => scene.id !== sceneId
                ) || [],
            },
          },
        }));

        return;
      }

      toast.error(`Deleting scene '${deletedSceneLabel}' failed.`);
    } catch (error: any) {
      console.log("API request failed:", error.message);
      throw Error(error.message);
    }
  };

  const handleAddScene = () => {
    if (!newScene.label.trim()) {
      setErrors((prev) => ({
        ...prev,
        label: "Scene label is required.",
      }));

      return;
    }

    addScene();

    setNewScene(DEFAULT_NEW_SCENE);
    setEditScene(DEFAULT_EDIT_SCENE);
  };

  const handleUpdateScene = (sceneId: string) => {
    if (!editScene.label.trim()) {
      setErrors((prev) => ({
        ...prev,
        label: "Scene label is required.",
      }));

      return;
    }

    updateScene(sceneId, editScene.label);

    setNewScene(DEFAULT_NEW_SCENE);
    setEditScene(DEFAULT_EDIT_SCENE);
  };

  const scenes = (
    <Wrapper classNames="flex flex-col gap-3">
      {sceneTypeScenes.map((scene) => (
        <div key={`scene-${scene.id}`}>
          {editScene.showFormId === scene.id ? (
            <Authenticated>
              <div className="relative">
                <Input
                  name="label"
                  value={editScene.label}
                  placeholder="Label..."
                  handleInputChange={(e) => handleInputChange(e, true)}
                  error={errors.label}
                  classNames="h-12"
                  ref={inputRef}
                />
                <button
                  className="absolute top-3 right-3 hover:scale-125"
                  onClick={() => handleUpdateScene(scene.id)}
                >
                  <VscArrowRight size={24} className="text-primary"/>
                </button>
              </div>
            </Authenticated>
          ) : (
            <div className="flex flex-row gap-4 justify-between">
              <div onClick={() => handleViewScene(scene)}>
                <Label
                  text={scene.label}
                  theme={theme}
                  classNames="cursor-pointer"
                />
              </div>

              <Authenticated>
                <PopupMenu
                  key={uniqid()}
                  theme={theme}
                  id={scene.id}
                  handleEdit={() => {
                    handleViewScene(scene);
                    setEditScene(() => ({
                      label: scene.label,
                      showFormId: scene.id,
                    }));
                  }}
                  handleDelete={() => deleteScene(scene.id)}
                />
              </Authenticated>
            </div>
          )}
        </div>
      ))}

      <Authenticated>
        <Wrapper classNames="relative">
          {newScene.showForm ? (
            <>
              <Input
                name="label"
                value={newScene.label}
                placeholder="Label..."
                handleInputChange={handleInputChange}
                error={errors.label}
                classNames="h-12"
                ref={inputRef}
              />
              <button
                className="absolute top-3 right-3 hover:scale-125"
                onClick={handleAddScene}
              >
                <VscArrowRight size={24} className="text-primary"/>
              </button>
            </>
          ) : (
            <Button
              icon={GoPlus}
              handleClick={() => {
                setNewScene((prev) => ({
                  ...prev,
                  showForm: !prev.showForm,
                }));
                setEditScene(DEFAULT_EDIT_SCENE);
              }}
              theme={theme}
            />
          )}
        </Wrapper>
      </Authenticated>
    </Wrapper>
  );

  return potreeDisplayMode === sceneTypeKey ? (
    <Accordion
      label={sceneTypeLabel}
      body={scenes}
      defaultOpen={true}
      labelColor="text-lgray"
      openAccordionIcon={HiOutlineMinus}
      closeAccordionIcon={HiOutlinePlus}
    />
  ) : null;
};

export default SceneTypeAccordion;
