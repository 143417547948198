import { DEFAULTS } from "../../../constants/defaults";
import { Theme } from "../../../types";
import { potreeMeasuringToolsHelper } from "../../../utils/potreeMeasuringToolsHelper";
import { usePotree } from "../../../hooks/usePotree";
import { useToggle } from "../../../hooks/useToggle";

interface ToolButtonProps {
  theme?: Theme;
  toolName: string;
  action: string | null;
  toolIconSrc: string;
  classNames?: string;
}

const ToolButton: React.FC<ToolButtonProps> = ({
  theme = "light",
  toolName,
  action,
  toolIconSrc,
  classNames,
}) => {
  const { potreeViewer } = usePotree();
  const { potreeDisplayMode } = useToggle();

  theme = potreeDisplayMode === DEFAULTS.potreeDisplayTab ? "light" : "dark";

  const handleClick = (toolName: string, action: string | null) => {
    console.log("ToolButton.handleClick()", toolName, action);
    if (action) {
      potreeMeasuringToolsHelper[action](potreeViewer);
    }
  };

  return (
    <div
      className={`transition hover:scale-150 py-3 px-2 max-w-[32px] 
      ${
        action
          ? "cursor-pointer"
          : "cursor-not-allowed opacity-50 hover:!scale-100"
      }
      ${theme === "light" ? "bg-primary" : "bg-white"}
      ${classNames ?? ""}
      `}
      onClick={() => handleClick(toolName, action)}
    >
      <img
        src={`/assets/icons/${theme}__${toolIconSrc}`}
        alt={`${toolName} icon`}
      />
    </div>
  );
};

export default ToolButton;
