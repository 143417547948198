// import { Theme } from "../../types";

// interface LabelProps extends Theme {
//   text: string;
// }

// const Label: React.FC<LabelProps> = ({ text, theme = "dark" }) => {
//   return (
//     <label
//       className={`text-base-regular ${
//         theme === "dark" ? "text-secondary" : "text-primary"
//       }`}
//     >
//       {text}
//     </label>
//   );
// };

// export default Label;

import { Theme } from "../../types";

interface LabelProps {
  theme?: Theme;
  text: string;
  classNames?: string;
}

const Label: React.FC<LabelProps> = ({ theme, text, classNames }) => {
  return (
    <label
      className={`text-base-regular 
      ${classNames ?? ""} 
      ${theme === "dark" ? "text-secondary" : "text-primary"}
      `}
    >
      {text}
    </label>
  );
};

export default Label;
