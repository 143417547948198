import { RxDotsVertical } from "react-icons/rx";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Theme } from "../../types";

interface ProjectEditMenuProps {
  theme?: Theme;
  projectId: number | string;
}

const ProjectEditMenu: React.FC<ProjectEditMenuProps> = ({
  theme = "dark",
  projectId,
}) => {
  return (
    <Menu as="div" className="relative inline-block">
      <div>
        <Menu.Button>
          <RxDotsVertical
            size={24}
            aria-hidden="true"
            className={`${
              theme === "dark"
                ? "text-white hover:text-secondary"
                : "text-lgray hover:text-secondary"
            }`}
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-gray shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to={`/project/${projectId}/edit`}
                  className={`block px-4 py-2 ${
                    active ? "hover:text-secondary" : "visited:text-white"
                  }`}
                >
                  Edit
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to={`/project/${projectId}/archive`}
                  className={`block px-4 py-2 ${
                    active ? "hover:text-secondary" : "visited:text-white"
                  }`}
                >
                  Archive
                </Link>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ProjectEditMenu;
