import { MdLogout } from "react-icons/md";
import { useAuth } from "../../hooks/useAuth";
import Container from "../shared/Container";
import Button from "../shared/Button";

const Header = () => {
  const auth = useAuth();

  if (!auth.token) return null;

  return (
    <Container>
      <header className="relative">
        <nav className="absolute right-0 flex items-center justify-end h-16">
          <ul className="flex flex-row items-center">
            <li>
              <Button
                label="logout"
                handleClick={() => auth.logout({})}
                classNames="hidden sm:flex"
              />
              <Button
                icon={MdLogout}
                handleClick={() => auth.logout({})}
                classNames="flex sm:hidden"
              />
            </li>
          </ul>
        </nav>
      </header>
    </Container>
  );
};

export default Header;
