import React, {
  createContext,
  ReactNode,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
} from "react";
import { useParams } from "react-router-dom";
import { DEFAULTS } from "../constants/defaults";
import { Project, Theme } from "../types";
import { authTokenHeader } from "../utils/helper";
import { useAuth } from "../hooks/useAuth";

interface PotreeContextProps {
  theme: Theme;

  // Project
  project: Project | null;
  loading: boolean;
  error: {
    message: string;
    status?: number;
  } | null;
  setProject: Dispatch<SetStateAction<Project | null>>;

  projectPcs: any;
  setProjectPcs: Dispatch<SetStateAction<any>>;

  // Potree Viewer
  potreeViewer: any;
  setPotreeViewer: Dispatch<SetStateAction<any>>;
}

export const PotreeContext = createContext<PotreeContextProps | undefined>(
  undefined
);

export const PotreeProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { projectId, presentationId } = useParams();
  const { token } = useAuth();

  const [theme] = useState<Theme>(DEFAULTS.theme as Theme);
  const [projectPcs, setProjectPcs] = useState([]);
  const [potreeViewer, setPotreeViewer] = useState(null);
  const [project, setProject] = useState<Project | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<{
    message: string;
    status?: number;
  } | null>(null);

  const fetchProject = async () => {
    setLoading(true);

    try {
      let response: any;

      if (presentationId) {
        // Fetch project by presentation token
        response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/project/${projectId}?token=${presentationId}`,
          {
            method: "GET",
          }
        );
      } else if (token) {
        // Fetch project by logged in user jwt token
        response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/project/${projectId}`,
          {
            method: "GET",
            headers: authTokenHeader(token),
          }
        );
      } else {
        setError(() => ({
          status: 400,
          message: "Fetching project data failed.",
        }));

        return;
      }

      if (response.ok) {
        const project = await response.json();

        setProject(project);

        return;
      }

      setError(() => ({
        status: response.status,
        message: response.statusText || "Something went wrong...",
      }));
    } catch (error: any) {
      setError(() => ({
        status: error.status,
        message: error.message,
      }));
    } finally {
      // TODO: Remove setTimeout, its just for make loading delay
      setTimeout(() => setLoading(false), 2000);
    }
  };

  useEffect(() => {
    projectId
      ? fetchProject()
      : setError(() => ({
          status: 404,
          message: "Not found.",
        }));
  }, [projectId, presentationId]);

  return (
    <PotreeContext.Provider
      value={{
        theme,
        project,
        loading,
        error,
        setProject,
        projectPcs,
        setProjectPcs,
        potreeViewer,
        setPotreeViewer,
      }}
    >
      {children}
    </PotreeContext.Provider>
  );
};
