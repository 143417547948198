import { usePotree } from "../../../hooks/usePotree";
import Label from "../../shared/Label";

const Shortcuts = () => {
  const { theme } = usePotree();

  return (
    <button className="text-primary flex items-center gap-8">
      <Label text="Shortcuts" theme={theme} />
    </button>
  );
};

export default Shortcuts;
