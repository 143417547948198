import { IconType } from "react-icons";
import { GrDown, GrUp } from "react-icons/gr";
import { ReactNode, useState } from "react";

interface AccordionProps {
  label: string;
  body: ReactNode;
  defaultOpen?: boolean;
  classNames?: string;
  labelColor?: string;
  openAccordionIcon?: IconType;
  closeAccordionIcon?: IconType;
}

const Accordion: React.FC<AccordionProps> = ({
  label,
  body,
  classNames,
  defaultOpen = false,
  labelColor,
  openAccordionIcon: IconOpen,
  closeAccordionIcon: IconClose,
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(defaultOpen);

  const open = IconOpen ? (
    <IconOpen size={24} className={labelColor ?? ""} />
  ) : (
    <GrUp size={24} className={labelColor ?? ""} />
  );
  const close = IconClose ? (
    <IconClose size={24} className={labelColor ?? ""} />
  ) : (
    <GrUp size={24} className={labelColor ?? ""} />
  );

  return (
    <section className={`text-primary flex flex-col gap-4 ${classNames ?? ""}`}>
      {/* Accordion Header */}
      <div
        className="flex items-center justify-between cursor-pointer"
        onClick={() => setIsAccordionOpen(() => !isAccordionOpen)}
      >
        <p className={`text-base-regular ${labelColor ?? ""}`}>{label}</p>
        <div>{isAccordionOpen ? open : close}</div>
      </div>

      {/* Accordion Body */}
      <div
        className={`transition-all duration-300 ease-in-out ${
          isAccordionOpen ? "max-h-screen" : "max-h-0 overflow-hidden"
        }`}
      >
        <div>{body}</div>
      </div>
    </section>
  );
};

export default Accordion;
