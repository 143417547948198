import { useToggle } from "../../../hooks/useToggle";

const Overlay = () => {
  const { isLeftSideMenuOpen: isOpen } = useToggle();

  return (
    <span
      style={{ background: "rgba(178, 178, 178, 0.3)" }}
      className={`z-40 backdrop-blur-sm fixed top-0 left-0 w-full h-full ${
        isOpen ? "block" : "hidden"
      }`}
    />
  );
};

export default Overlay;
