const ProgressBar = ({
  progress,
  label,
}: {
  progress: number;
  label?: string;
}) => {
  return (
    <div className="flex flex-col gap-4">
      {label && (
        <label className="text-base-regular text-primary">{label}</label>
      )}
      <div className="flex items-center relative w-full h-2 bg-lgray text-white rounded-3xl overflow-hidden">
        <span
          className="absolute h-2 bg-gray"
          style={{ width: `${progress}%` }}
        ></span>
      </div>
    </div>
  );
};

export default ProgressBar;
