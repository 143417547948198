import { OrtoScene } from "../types";

export class OrtoSceneHelper {

  static applyOrtoScene(viewer: any, ortoScene: OrtoScene) {
    ortoScene?.json_data.forEach((field_item: any) => {
      const json_data_item = field_item.value;
      if (json_data_item.view) {
        OrtoSceneHelper.setViewData(viewer, json_data_item.view);
      }
      // setViewData(viewer, field_item);
      // setVolumesData(viewer, field_item);
      // ...
    });
  }

  /**
   * Sets pointcloud attributes from Orto scene json_data.
   *
   * @param viewer
   *   Potree viewer reference.
   * @param ortoScene
   *   Orto scene entity.
   * @param setProjectPcs
   *   ...
   */
  static setPointcloudsData(viewer: any, ortoScene: OrtoScene, setProjectPcs: any) {
    // TODO: iterate over json_data.
    const jsonData: any = ortoScene?.json_data[0].value;
    const pointcloudsData = jsonData.pointclouds || [];
    const pcDataById: any[] = [];
    pointcloudsData.forEach((data: any) => {
      pcDataById[data.o_id] = data;
    });
    // Update pointcloud visiblity in scene.
    viewer.scene.pointclouds.forEach((pointcloud: any) => {
      pointcloud.visible = !!pcDataById[pointcloud.o_id]?.visible
    });
    // Update pointcloud checkboxes in left sidebar.
    setProjectPcs((prev: any) => {
        return prev.map((checkboxItem: any, index: number) => {
          checkboxItem.visibile = !!pcDataById[checkboxItem.id]?.visible;
          return checkboxItem;
        });
      }
    )
  }

  /**
   * Get view position and target for Orto scene json_data.
   */
  static getViewData(viewer: any) {
    const camera = viewer.scene.getActiveCamera();
    const view = viewer.scene.view;
    const position = camera.position.toArray();
    const target = view.getPivot().toArray();
    const transitionDuration = 0;
    return { position, target, transitionDuration };
  }

  /**
   * Set view position and target.
   *
   * @param viewer
   *   Potree viewer instance.
   * @param viewData
   *   View position + target from Orto scene json_data.
   */
  static setViewData(viewer: any, viewData: any) {
    viewer.scene.view.setView(
      viewData.position,
      viewData.target,
      viewData.transitionDuration,
    );
  }

  static setVolumesData() {

  }

}