import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { POTREE_PROJECT_MANAGE_FIELDS } from "../constants/fields";
import { FormFieldError, Pointcloud, Project } from "../types";
import { authTokenHeader, getDefaultOptions, isEmpty } from "../utils/helper";
import { useAuth } from "../hooks/useAuth";
import useApi from "../hooks/useApi";
import ProjectsInfoHeader from "../components/admin/ProjectsInfoHeader";
import Loading from "../components/shared/Loading";
import Error from "../components/shared/Error";
import Input from "../components/shared/Input";
import Textarea from "../components/shared/Textarea";
import MultiCheckbox from "../components/shared/MultiCheckbox";
import Radio from "../components/shared/Radio";
import Button from "../components/shared/Button";

const EditProject = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useAuth();

  const [project, setProject] = useState<Project | null>(null);
  const [pointclouds, setPointclouds] = useState<any>();
  const [errors, setErrors] = useState<FormFieldError>({});

  const {
    data: projectResult,
    loading: projectLoading,
    error: projectError,
  } = useApi<Project | null>({
    url: `${process.env.REACT_APP_API_BASE_URL}/api/v1/project/${id}`,
    headers: authTokenHeader(token),
  });

  const {
    data: pointcloudResult,
    loading: pointcloudLoading,
    error: pointcloudError,
  } = useApi<Pointcloud | null>({
    url: `${process.env.REACT_APP_API_BASE_URL}/api/v1/pointclouds`,
    headers: authTokenHeader(token),
  });

  useEffect(() => {
    if (projectResult && !isEmpty(projectResult) && id && !isEmpty(id))
      setProject(projectResult);
  }, [projectResult, projectLoading, projectError, id]);

  useEffect(() => {
    if (pointcloudResult && !isEmpty(pointcloudResult))
      setPointclouds(pointcloudResult);
  }, [pointcloudResult, pointcloudLoading, pointcloudError]);

  // Empty errors whenever project input value update
  useEffect(() => setErrors({}), [setProject]);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) =>
    setProject((prev) => ({
      ...(prev as Project),
      [e.target.name]: e.target.value,
    }));

  const handleCheckboxChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: string | number
  ) => {
    const fieldName: string = e.target.name;

    setProject((prevProject: any) => {
      if (prevProject && prevProject[fieldName]) {
        const field = prevProject[fieldName] as (string | number)[];

        const updatedField = field.includes(value)
          ? field.filter((item) => item !== value)
          : [...field, value];

        return {
          ...prevProject,
          [fieldName]: updatedField,
        };
      }

      return prevProject;
    });
  };

  const handleRadioChange = (value: string | number) =>
    setProject((prev) => ({
      ...(prev as any),
      field_splat_quality: value,
    }));

  const updateProject = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/project/${id}`,
        {
          method: "POST",
          headers: authTokenHeader(token),
          body: JSON.stringify(project),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        toast.success("Project updated successfully.");
        return;
      }

      toast.error("Project update failed.");
    } catch (error: any) {
      console.log("API request failed:", error.message);
      throw Error(error.message);
    }
  }, [project, token, id]);

  const onEditProjectFormSubmit = () => {
    updateProject();
  };

  return (
    <>
      <ProjectsInfoHeader />
      <section className="mt-10 overflow-x-auto">
        {projectLoading ? (
          <Loading classNames="flex justify-center" theme="dark" />
        ) : (
          <>
            {projectError && (
              <Error message={projectError.message} classNames="!p-0" />
            )}

            {!project ? (
              <Error message={`Project ${id} not found!`} classNames="!p-0" />
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="flex flex-col gap-4">
                  <Input
                    name="label"
                    value={project.label}
                    placeholder="Project Name"
                    onInputChange={handleInputChange}
                    error={errors.label}
                    width="full"
                  />

                  <Input
                    name="id"
                    value={project.id}
                    placeholder="ID"
                    onInputChange={handleInputChange}
                    error={errors.id}
                    width="full"
                  />

                  <Input
                    name="created"
                    value={project.created}
                    placeholder="Created"
                    onInputChange={handleInputChange}
                    error={errors.created}
                    width="full"
                  />

                  <Textarea
                    name="field_json5"
                    value={project.field_json5 ?? ""}
                    placeholder="JSON5"
                    onInputChange={handleInputChange}
                    error={errors.field_json5}
                  />

                  <Radio
                    fieldLabel="Splat Quality"
                    defaultOptions={
                      POTREE_PROJECT_MANAGE_FIELDS.field_splat_quality
                    }
                    selectedValue={project.field_splat_quality}
                    handleChange={handleRadioChange}
                    error={errors.field_splat_quality}
                  />

                  <MultiCheckbox
                    fieldLabel="Tools"
                    fieldName="field_tools"
                    defaultOptions={POTREE_PROJECT_MANAGE_FIELDS.field_tools}
                    checkedValues={project.field_tools ?? []}
                    handleChange={handleCheckboxChange}
                    error={errors.field_tools}
                  />

                  {pointclouds && (
                    <MultiCheckbox
                      fieldLabel="Pointclouds"
                      fieldName="field_pointclouds"
                      defaultOptions={getDefaultOptions(
                        pointclouds,
                        "label",
                        "id"
                      )}
                      checkedValues={project.field_pointclouds}
                      handleChange={handleCheckboxChange}
                      error={errors.field_pointclouds}
                    />
                  )}

                  <div className="flex flex-row gap-6">
                    <Button
                      label="cancel"
                      handleClick={() => navigate("/dashboard")}
                      type="submit"
                    />

                    <Button
                      label="update"
                      handleClick={onEditProjectFormSubmit}
                      type="submit"
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </section>
    </>
  );
};

export default EditProject;
