import { toast } from "react-toastify";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { POTREE_PROJECT_MANAGE_FIELDS } from "../constants/fields";
import { FormFieldError, Pointcloud } from "../types";
import { authTokenHeader, getDefaultOptions, isEmpty } from "../utils/helper";
import { useAuth } from "../hooks/useAuth";
import useApi from "../hooks/useApi";
import ProjectsInfoHeader from "../components/admin/ProjectsInfoHeader";
import Button from "../components/shared/Button";
import Input from "../components/shared/Input";
import Textarea from "../components/shared/Textarea";
import Radio from "../components/shared/Radio";
import MultiCheckbox from "../components/shared/MultiCheckbox";
import FileUpload, { UploadableFile } from "../components/shared/FileUpload";

const AddProject = () => {
  const { token } = useAuth();

  const [pointCloudFiles, setPointCloudFiles] = useState<UploadableFile[]>([]);
  const [pointclouds, setPointclouds] = useState<any>();
  const [project, setProject] = useState({
    label: "",
    description: null,
    field_splat_quality: "",
    field_sidebar: "",
    field_tools: [],
    field_pointclouds: [],
    status: 0,
  });
  const [errors, setErrors] = useState<FormFieldError>({});

  const {
    data: pointcloudResult,
    loading: pointcloudLoading,
    error: pointcloudError,
  } = useApi<Pointcloud | null>({
    url: `${process.env.REACT_APP_API_BASE_URL}/api/v1/pointclouds`,
    headers: authTokenHeader(token),
  });

  useEffect(() => {
    if (pointcloudResult && !isEmpty(pointcloudResult))
      setPointclouds(pointcloudResult);
  }, [pointcloudResult, pointcloudLoading, pointcloudError]);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setProject((prev) => ({
      ...(prev as any),
      [e.target.name]: e.target.value,
    }));

    setErrors({});
  };

  const handleCheckboxChange = (
    e: ChangeEvent<HTMLInputElement>,
    value: string | number
  ) => {
    const fieldName: string = e.target.name;

    setProject((prevProject: any) => {
      if (prevProject && prevProject[fieldName]) {
        const field = prevProject[fieldName] as (string | number)[];

        const updatedField = field.includes(value)
          ? field.filter((item) => item !== value)
          : [...field, value];

        return {
          ...prevProject,
          [fieldName]: updatedField,
        };
      }

      return prevProject;
    });
  };

  const createProject = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/project`,
        {
          method: "PUT",
          headers: authTokenHeader(token),
          body: JSON.stringify(project),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        toast.success("Project created successfully.");
        return;
      }

      toast.error("Project creation failed.");
    } catch (error: any) {
      console.log("API request failed:", error.message);
      throw Error(error.message);
    }
  }, [project, token]);

  const onCreateProjectFormSubmit = () => {
    createProject();
  };

  return (
    <>
      <ProjectsInfoHeader />
      <section className="mt-10 overflow-x-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="flex flex-col gap-4">
            <Input
              name="label"
              value={project?.label ?? ""}
              placeholder="Project Name"
              onInputChange={handleInputChange}
              error={errors.label}
              width="full"
            />

            <Textarea
              name="description"
              value=""
              onInputChange={handleInputChange}
              error={errors.description}
            />

            <Radio
              fieldLabel="Splat Quality"
              defaultOptions={POTREE_PROJECT_MANAGE_FIELDS.field_splat_quality}
              selectedValue={project.field_splat_quality}
              handleChange={(value: string | number) =>
                setProject((prev) => ({
                  ...(prev as any),
                  field_splat_quality: value,
                }))
              }
              error={errors.field_splat_quality}
            />

            <Radio
              fieldLabel="Sidebar"
              defaultOptions={POTREE_PROJECT_MANAGE_FIELDS.field_sidebar}
              selectedValue={project.field_sidebar}
              handleChange={(value: string | number) =>
                setProject((prev) => ({
                  ...(prev as any),
                  field_sidebar: value,
                }))
              }
              error={errors.field_sidebar}
            />
            <MultiCheckbox
              fieldLabel="Tools"
              fieldName="field_tools"
              defaultOptions={POTREE_PROJECT_MANAGE_FIELDS.field_tools}
              checkedValues={project.field_tools}
              handleChange={handleCheckboxChange}
              error={errors.field_tools}
            />

            {pointclouds && (
              <MultiCheckbox
                fieldLabel="Pointclouds"
                fieldName="field_pointclouds"
                defaultOptions={getDefaultOptions(pointclouds, "label", "id")}
                checkedValues={project.field_pointclouds}
                handleChange={handleCheckboxChange}
                error={errors.field_pointclouds}
              />
            )}

            <Button
              label="Create"
              handleClick={onCreateProjectFormSubmit}
              type="submit"
            />
          </div>

          <div>
            <FileUpload
              handleUploadedFiles={setPointCloudFiles}
              allowedFileType={{
                "image/jpeg": [".jpeg", ".jpg"],
                "image/png": [".png"],
              }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default AddProject;
